<template>
  <lkps-table
    :api-endpoint="apiEndpoint"
    :headers="headers"
    :form="form"
    single-export="Dapat Ditinjau"
  />
</template>

<script>
import LkpsTable from '@views/pages/components/LKPSTable.vue'

export default {
  components: {
    LkpsTable,
  },
  data() {
    return {
      apiEndpoint: 'lkps_ipk',
      headers: [
        [{
          label: 'Tahun Lulus',
          dataKey: 'tahun_lulus',
          attributes: { rowspan: 2 },
        }, {
          label: 'Jumlah Lulusan',
          dataKey: 'jml_lulus',
          attributes: { rowspan: 2 },
        }, {
          label: 'Indeks Prestasi Kumulatif (IPK)',
          attributes: { colspan: 3 },
        }],
        [{
          label: 'Minimum	',
          dataKey: 'ipk_min',
        }, {
          label: 'Rata-Rata	',
          dataKey: 'ipk_rata',
        }, {
          label: 'Maksimum',
          dataKey: 'ipk_maks',
        }]
      ],
      form: {
        inputs: [{
          label: 'Tahun Lulus',
          name: 'tahun_lulus',
          dataKey: 'tahun_lulus',
          type: 'textarea',
          placeholder: 'TS-1',
          rules: 'required',
        }, {
          label: 'Jumlah Lulusan',
          name: 'jml_lulus',
          dataKey: 'jml_lulus',
          type: 'number',
          defaultValue: 0,
        }, {
          label: 'Minimum	',
          name: 'ipk_min',
          dataKey: 'ipk_min',
          type: 'textarea',
          defaultValue: 0,
        }, {
          label: 'Rata-Rata	',
          name: 'ipk_rata',
          dataKey: 'ipk_rata',
          type: 'textarea',
          defaultValue: 0,
        }, {
          label: 'Maksimum',
          name: 'ipk_maks',
          dataKey: 'ipk_maks',
          type: 'textarea',
          defaultValue: 0,
        }]
      }
    }
  },
}
</script>
